import React, { useEffect, useState } from 'react';
import './GerarCupons.css';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function GerarCupons() {
  const [creditos, setCreditos] = useState(0);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cuponsGerados, setCuponsGerados] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'clientes', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCreditos(userData.creditos || 0);
          setNome(userData.nome);
          setEmail(userData.email);
        }
      }
    };

    fetchUserData();
  }, []);





const generateRandomCoupon = async () => {
  const user = auth.currentUser;
  if (!user || creditos <= 0) {
    console.log("Usuário não autenticado ou sem créditos!");
    return;
  }

  const userDocRef = doc(db, 'clientes', user.uid);
  const generatedCoupons = new Set();
  const maxAttempts = 1000; // Limite de tentativas para evitar loops infinitos
  let attempts = 0;

  console.log("Iniciando geração de cupons...");

  while (generatedCoupons.size < creditos && attempts < maxAttempts) {
    let coupon = '0'; // Começa com 0
    for (let i = 0; i < 5; i++) {
      coupon += Math.floor(Math.random() * 10).toString();
    }

    const prefix = coupon.slice(1, 3); // Pega os dois primeiros dígitos após o "0"
    const prefixDocRef = doc(db, 'cupons_particionados', prefix);

    // Verificar cupons existentes na partição
    const prefixDoc = await getDoc(prefixDocRef);
    const existingCoupons = prefixDoc.exists() ? new Set(prefixDoc.data().cupons || []) : new Set();

    if (!existingCoupons.has(coupon)) {
      generatedCoupons.add(coupon);
      existingCoupons.add(coupon);

      // Atualizar a partição com o novo cupom
      await setDoc(prefixDocRef, {
        cupons: Array.from(existingCoupons),
      }, { merge: true });
    }

    attempts++;
  }

  if (generatedCoupons.size === 0) {
    console.log("Não foi possível gerar cupons únicos. Tente novamente.");
    return;
  }

  console.log("Cupons gerados:", Array.from(generatedCoupons));

  // Atualizar o usuário no Firestore
  try {
    await updateDoc(userDocRef, {
      creditos: 0,
      historicoCreditos: increment(creditos),
      cupons: increment(generatedCoupons.size),
    });

    generatedCoupons.forEach(async (coupon) => {
      await setDoc(doc(db, 'cupons', coupon), {
        numero: coupon,
        nome: nome,
        email: email,
        data: new Date().toISOString(),
      });

      await updateDoc(userDocRef, {
        [`cuponsGerados.${coupon}`]: {
          numero: coupon,
          data: new Date().toISOString(),
        },
      });
    });

    setCuponsGerados(Array.from(generatedCoupons));
    setShowModal(true);
  } catch (error) {
    console.error("Erro ao salvar dados no Firestore:", error);
  }
};




  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload(); // Atualiza a página ao fechar o modal
  };

  return (
    <div className="gerar-cupons-container">
      <button onClick={() => navigate('/inicio')} className="voltar-button">
        <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Início
      </button>
      <div className="content-box">
        <h1>Gerar Cupons</h1>
        <div className="info-container">
          <div className="info-box">
            <p className="highlight">Você possui <strong>{creditos}</strong> crédito(s).</p>
            <p>A cada R$100 abastecidos, você ganha um crédito.</p>
            <p>No momento, você tem <strong>{creditos}</strong> crédito(s) para gerar cupons.</p>
            <p className="bold-text">Cada cupom é uma chance de ganhar!</p>
          </div>
        </div>
        <button onClick={generateRandomCoupon} className="gerar-cupons-button">
          <FontAwesomeIcon icon={faTicketAlt} /> Gerar Cupons
        </button>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Cupons Gerados</h2>
              <div className="cupons-lista">
                {cuponsGerados.map((cupom, index) => (
                  <p key={index} className="cupom-gerado"><strong>{cupom}</strong></p>
                ))}
              </div>
              <p>Boa sorte!</p>
              <button onClick={handleModalClose} className="modal-button">Fechar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GerarCupons;
